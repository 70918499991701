<template>
  <main class=" ">
    <div class="mb-16 px-4 max-w-sm md:max-w-3xl lg:max-w-6xl mx-auto">
      <div class="flex justify-between items-center my-10">
        <h2
          class="uppercase text-lg md:text-2xl font-semibold text-gray-700 dark:text-gray-200"
        >
          Reviews
        </h2>
      </div>

      <div class="bg-white dark:bg-gray-800 mt-10">
        <filter-button id="filters">
          <template v-slot:search>
            <div class="w-56 flex md:ml-0 bg-white dark:bg-gray-800">
              <label for="search_field" class="sr-only">Search</label>
              <div
                class="relative w-full text-gray-400 focus-within:text-gray-600"
              >
                <div
                  class="pointer-events-none absolute inset-y-0 left-0 flex items-center bg-white dark:bg-gray-800"
                >
                  <svg
                    class="h-5 w-5 stroke-current"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M20.9999 21L16.6499 16.65"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>

                <div
                  v-if="filter.searchByName"
                  class="absolute inset-y-0 right-0 focus:outline-none flex items-center"
                >
                  <button type="button" @click="clearSearchInput">
                    <svg
                      class="h-5 w-5 stroke-current"
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18.7612 6.7998L6.76123 18.7998"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M6.76123 6.7998L18.7612 18.7998"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
                <input
                  autocomplete="off"
                  @keyup="getReviews()"
                  v-model="filter.searchByName"
                  id="search_field"
                  class="bg-white dark:bg-gray-800 block h-full w-full border-0 py-2 pl-8 pr-3 text-gray-900 dark:text-grey-H-400 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
                  placeholder="Search"
                  type="text"
                  name="search"
                />
              </div>
            </div>
          </template>
          <div
            id="filters"
            class="hidden mt-8 grid grid-cols-2 gap-8 max-w-lg mx-auto text-black dark:text-grey-H-400"
          ></div>
        </filter-button>
      </div>

      <div
        class="bg-gray-50 dark:text-gray-400 dark:bg-gray-800 rounded-lg shadow-md relative mt-6"
      >
        <div v-if="showReviews" class="w-full whitespace-no-wrap">
          <div
            class="flex justify-between items-center pt-4 pb-2 text-xs font-semibold tracking-wide text-left text-gray-500 border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800"
          >
            <span class="w-1/2 md:w-1/4 px-4 py-3">ProductName</span>
            <span class="w-1/2 md:w-1/4 px-4 py-3">Comment</span>
            <span class="w-1/2 md:w-1/4 px-4 py-3">Rate</span>
            <span class="w-1/2 md:w-1/4 px-4 py-3">Created By</span>
            <span class="w-1/2 md:w-1/4 px-4 py-3">Created At</span>
            <span class="w-1/2 md:w-1/4 px-4 py-3">Procedures</span>
          </div>
          <div class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
            <div
              :key="productReviewId"
              v-for="(productReview, productReviewId) in productReviews"
              class="flex justify-between items-center text-gray-700 dark:text-gray-400"
            >
              <span class="w-1/2 md:w-1/4 px-4 py-3 text-sm">
                <div>
                  <p class="font-semibold">{{ productReview.productName }}</p>
                </div>
              </span>
              <span class="w-1/2 md:w-1/4 px-4 py-3 text-sm">
                <div>
                  <p class="font-semibold">{{ productReview.description }}</p>
                </div>
              </span>
              <span class="w-1/2 md:w-1/4 px-4 py-3 text-sm">
                <div class="flex space-x-1">
                  <svg
                    :key="index"
                    v-for="(rate, index) in productReview.rate"
                    class="h-5 w-5"
                    viewBox="0 -10 511.98685 511"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="m510.652344 185.902344c-3.351563-10.367188-12.546875-17.730469-23.425782-18.710938l-147.773437-13.417968-58.433594-136.769532c-4.308593-10.023437-14.121093-16.511718-25.023437-16.511718s-20.714844 6.488281-25.023438 16.535156l-58.433594 136.746094-147.796874 13.417968c-10.859376 1.003906-20.03125 8.34375-23.402344 18.710938-3.371094 10.367187-.257813 21.738281 7.957031 28.90625l111.699219 97.960937-32.9375 145.089844c-2.410156 10.667969 1.730468 21.695313 10.582031 28.09375 4.757813 3.4375 10.324219 5.1875 15.9375 5.1875 4.839844 0 9.640625-1.304687 13.949219-3.882813l127.46875-76.183593 127.421875 76.183593c9.324219 5.609376 21.078125 5.097657 29.910156-1.304687 8.855469-6.417969 12.992187-17.449219 10.582031-28.09375l-32.9375-145.089844 111.699219-97.941406c8.214844-7.1875 11.351563-18.539063 7.980469-28.925781zm0 0"
                      fill="#ffc107"
                    />
                  </svg>
                </div>
              </span>
              <span class="w-1/2 md:w-1/4 px-4 py-3 text-sm">
                <div>
                  <p class="font-semibold">{{ productReview.fullName }}</p>
                </div>
              </span>
              <span class="w-1/2 md:w-1/4 px-4 py-3 text-sm">
                <div>
                  <p class="font-semibold">{{ productReview.createdOn }}</p>
                </div>
              </span>
              <span class="w-1/2 md:w-1/4 px-4 py-3 text-sm">
                <div class="flex justify-between items-center">
                  <div class="">
                    <button
                      @click="
                        prepareDelete(
                          productReview.productName,
                          productReview.productReviewId,
                          index
                        )
                      "
                    >
                      <svg
                        class="w-6 h-6"
                        height="512pt"
                        viewBox="-57 0 512 512"
                        width="512pt"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="m156.371094 30.90625h85.570312v14.398438h30.902344v-16.414063c.003906-15.929687-12.949219-28.890625-28.871094-28.890625h-89.632812c-15.921875 0-28.875 12.960938-28.875 28.890625v16.414063h30.90625zm0 0"
                        />
                        <path
                          d="m344.210938 167.75h-290.109376c-7.949218 0-14.207031 6.78125-13.566406 14.707031l24.253906 299.90625c1.351563 16.742188 15.316407 29.636719 32.09375 29.636719h204.542969c16.777344 0 30.742188-12.894531 32.09375-29.640625l24.253907-299.902344c.644531-7.925781-5.613282-14.707031-13.5625-14.707031zm-219.863282 312.261719c-.324218.019531-.648437.03125-.96875.03125-8.101562 0-14.902344-6.308594-15.40625-14.503907l-15.199218-246.207031c-.523438-8.519531 5.957031-15.851562 14.472656-16.375 8.488281-.515625 15.851562 5.949219 16.375 14.472657l15.195312 246.207031c.527344 8.519531-5.953125 15.847656-14.46875 16.375zm90.433594-15.421875c0 8.53125-6.917969 15.449218-15.453125 15.449218s-15.453125-6.917968-15.453125-15.449218v-246.210938c0-8.535156 6.917969-15.453125 15.453125-15.453125 8.53125 0 15.453125 6.917969 15.453125 15.453125zm90.757812-245.300782-14.511718 246.207032c-.480469 8.210937-7.292969 14.542968-15.410156 14.542968-.304688 0-.613282-.007812-.921876-.023437-8.519531-.503906-15.019531-7.816406-14.515624-16.335937l14.507812-246.210938c.5-8.519531 7.789062-15.019531 16.332031-14.515625 8.519531.5 15.019531 7.816406 14.519531 16.335937zm0 0"
                        />
                        <path
                          d="m397.648438 120.0625-10.148438-30.421875c-2.675781-8.019531-10.183594-13.429687-18.640625-13.429687h-339.410156c-8.453125 0-15.964844 5.410156-18.636719 13.429687l-10.148438 30.421875c-1.957031 5.867188.589844 11.851562 5.34375 14.835938 1.9375 1.214843 4.230469 1.945312 6.75 1.945312h372.796876c2.519531 0 4.816406-.730469 6.75-1.949219 4.753906-2.984375 7.300781-8.96875 5.34375-14.832031zm0 0"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </span>
            </div>
          </div>
          <div
            v-if="total < 0"
            class="flex justify-between items-center px-4 py-3 text-xs font-semibold tracking-wide text-gray-500 uppercase border-t dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800"
          >
            <el-pagination
              background
              :page-size="filter.pageSize"
              layout="prev, pager, next"
              :total="total"
              :current-page.sync="filter.pageNo"
              @current-change="PageChanged"
              class="pagination justify-content-center pagination-sm"
            >
            </el-pagination>
          </div>
        </div>

        <div
          v-else
          id="emptyState"
          class="text-gray_5 text-sm text-center h-96 flex items-center justify-center"
        >
          <div
            v-if="loading"
            id="testtest"
            class="h-64 flex justify-center items-center"
          >
            <svg
              class="w-10 h-w-10 rounded-full"
              viewBox="0 0 38 38"
              stroke="#fff"
            >
              <g fill="none">
                <g transform="translate(1 1)" stroke-width="2">
                  <circle
                    stroke="#E1E7EC"
                    stroke-opacity=".5"
                    cx="18"
                    cy="18"
                    r="18"
                  />
                  <path stroke="black" d="M36 18c0-9.94-8.06-18-18-18">
                    <animateTransform
                      attributeName="transform"
                      type="rotate"
                      from="0 18 18"
                      to="360 18 18"
                      dur="1s"
                      repeatCount="indefinite"
                    />
                  </path>
                </g>
              </g>
            </svg>
          </div>

          <div
            v-else
            class="h-64 flex justify-center items-center w-full bg-white dark:bg-gray-800"
          >
            There are no Reviews...
          </div>
        </div>
      </div>
    </div>

    <!-- Start making sure delete it  -->
    <div v-show="makingSureDeleteIt">
      <button
        @click="makingSureDeleteIt = false"
        class="absolute bg-black bg-opacity-60 w-full h-full inset-0 top-0 z-40 flex justify-center items-center cursor-auto"
      >
        <div class="bg-white p-7 w-full max-w-lg rounded-xl cursor-auto z-50">
          <div class="flex justify-between items-start">
            <div class="text-left">
              <p class="text-2xl font-bold">
                Are you sure to delete {{ prepareDeleteReviewsName }} ?
              </p>
              <p class="text-sm mt-2">It will be permanently deleted.</p>
            </div>

            <button
              @click="makingSureDeleteIt = false"
              class="focus:outline-none"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M17.3941 19.5531L11.999 14.158L6.60398 19.5531C6.00806 20.149 5.04188 20.149 4.44596 19.5531C3.85004 18.9571 3.85004 17.991 4.44596 17.395L9.84101 12L4.44596 6.60496C3.85004 6.00904 3.85004 5.04286 4.44596 4.44694C5.04188 3.85102 6.00806 3.85102 6.60398 4.44694L11.999 9.84198L17.3941 4.44694C17.99 3.85102 18.9562 3.85102 19.5521 4.44694C20.148 5.04286 20.148 6.00904 19.5521 6.60496L14.157 12L19.5521 17.395C20.148 17.991 20.148 18.9571 19.5521 19.5531C18.9562 20.149 17.99 20.149 17.3941 19.5531Z"
                  fill="#676767"
                />
              </svg>
            </button>
          </div>

          <div class="mt-10 space-x-4 flex justify-start">
            <button
              @click="deleteButton()"
              class="uppercase bg-black text-xs md:text-sm font-medium tracking-widest w-32 py-3 text-surface-white rounded focus:outline-none hover:bg-white hover:text-black border hover:border-black transform duration-700"
            >
              Delete
            </button>
            <button
              @click="makingSureDeleteIt = false"
              class="uppercase bg-white text-xs md:text-sm font-medium tracking-widest w-32 py-3 text-black border border-black rounded focus:outline-none hover:shadow-xl transform duration-700"
            >
              Cancel
            </button>
          </div>
        </div>
      </button>
    </div>
    <!-- End making sure delete it  -->

    <!-- Start Delete Status -->
    <div v-show="deleteStatus">
      <button
        @click="deleteStatus = false"
        class="absolute bg-black bg-opacity-60 w-full h-full inset-0 top-0 z-40 flex justify-center items-center cursor-auto"
      >
        <div class="bg-white p-7 w-full max-w-lg rounded-xl cursor-auto z-50">
          <div class="flex justify-end">
            <button @click="deleteStatus = false" class="focus:outline-none">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M17.3941 19.5531L11.999 14.158L6.60398 19.5531C6.00806 20.149 5.04188 20.149 4.44596 19.5531C3.85004 18.9571 3.85004 17.991 4.44596 17.395L9.84101 12L4.44596 6.60496C3.85004 6.00904 3.85004 5.04286 4.44596 4.44694C5.04188 3.85102 6.00806 3.85102 6.60398 4.44694L11.999 9.84198L17.3941 4.44694C17.99 3.85102 18.9562 3.85102 19.5521 4.44694C20.148 5.04286 20.148 6.00904 19.5521 6.60496L14.157 12L19.5521 17.395C20.148 17.991 20.148 18.9571 19.5521 19.5531C18.9562 20.149 17.99 20.149 17.3941 19.5531Z"
                  fill="#676767"
                />
              </svg>
            </button>
          </div>

          <div class="flex justify-center mt-4">
            <svg
              width="60"
              height="60"
              viewBox="0 0 80 80"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="40" cy="40" r="38" stroke="black" stroke-width="4" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M56.3149 28.7293C57.2486 29.6808 57.225 31.2002 56.2621 32.123L36.2264 51.323C35.2843 52.2257 33.7871 52.2257 32.8451 51.323L23.7379 42.5957C22.775 41.673 22.7514 40.1535 23.6851 39.202C24.6188 38.2504 26.1563 38.227 27.1192 39.1498L34.5357 46.2569L52.8808 28.677C53.8437 27.7543 55.3812 27.7777 56.3149 28.7293Z"
                fill="black"
              />
            </svg>
          </div>

          <div class="text-center mt-8">
            <p class="text-2xl font-bold">Delete Successfuly</p>
            <p class="text-sm mt-2">Permanently deleted</p>
          </div>

          <div class="mt-10 flex justify-center">
            <button
              @click="deleteStatus = false"
              class="bg-black text-xs md:text-sm font-normal tracking-widest w-32 py-3 text-surface-white rounded focus:outline-none hover:bg-white hover:text-black border hover:border-black transform duration-700"
            >
              Close
            </button>
          </div>
        </div>
      </button>
    </div>
    <!-- End Delete Status -->
  </main>
</template>

<script>
export default {
  created() {},
  mounted() {
    this.getReviews();
  },
  data() {
    return {
      filter: {
        pageNo: 1,
        pageSize: 10,
        searchByName: "",
      },

      data: "",
      showReviews: false,
      loading: false,
      showPagination: false,

      pagination: {},

      total: 0,

      displayClearButton: false,

      toWatchReviewsLength: "",

      productReviews: [],

      makingSureDeleteIt: false,
      deleteStatus: false,
      prepareDeleteReviewsName: "",
      prepareDeleteReviewsId: "",
      prepareDeleteReviewsIndex: "",
    };
  },

  watch: {
    toWatchReviewsLength: function () {
      this.showReviews = false;
      this.loading = true;
      if (this.toWatchReviewsLength > 0) {
        setTimeout(() => {
          this.showReviews = true;
          this.loading = false;
        }, 1000);
      } else {
        setTimeout(() => {
          this.showReviews = false;
          this.loading = false;
        }, 1000);
      }
    },
  },

  methods: {
    PageChanged(pageNo) {
      this.filter.pageNo = pageNo;
      this.getReviews();
    },

    clearSearchInput() {
      this.filter.searchByName = "";
      this.getReviews();
    },

    getReviews() {
      this.loading = true;
      this.$http.reviewsService
        .GetReviews(this.filter)
        .then((res) => {
          this.data = res.data;
          this.productReviews = res.data.result.reviews;
          this.total = res.data.result.total;
          this.toWatchReviewsLength = res.data.result.total;
          this.loading = false;
        })
        .catch((err) => {
         this.loading = false;
          this.Successed = false;
          this.addErorr = err.data.result.message;     
             });
    },

    prepareDelete(productName, reviewsId, index) {
      this.prepareDeleteReviewsName = productName;
      this.prepareDeleteReviewsId = reviewsId;
      this.prepareDeleteReviewsIndex = index;
      this.makingSureDeleteIt = true;
    },

    deleteButton() {
      this.loading = true;

      this.$http.reviewsService
        .DeleteReview(this.prepareDeleteReviewsId)
        .then((res) => {
          this.loading = false;
          this.Successed = true;
          this.addSuccessed = res.data.response;
          this.productReviews.splice(this.prepareDeleteReviewsIndex, 1);

          this.deleteStatus = true;
        })
        .catch((err) => {
          this.loading = false;
          this.Successed = false;
          this.addErorr = err.data.result.message;
        });
    },
  },
};
</script>

<style>
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #171122;
  color: #fff;
}
</style>
